import React from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import ActiveBetweenRange from "../components/ActiveBetweenRange";

const ArtistLayout = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
  }
  background-color: white;
  color: #333;
  max-width: 1100px;
  padding: 2em;
  margin: 2em auto;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  > * {
    padding: 2rem;
  }
  h1 {
    padding: none;
    margin: none;
    span {
      font-size: 0.5em;
    }
  }
  h2 {
    font-size: 1em;
    font-weight: 400;
    padding-top: 2rem;
  }
  h3 {
    font-weight: 400;
    padding: none;
  }
`;

const TextContainer = styled.div``;

export default function artist({ data }) {
  const artist = data.artistData;
  const image = getImage(artist.image.asset);

  console.log(artist.image);
  console.log(image);

  return (
    <ArtistLayout>
      <GatsbyImage
        className="image"
        image={getImage(artist.image?.asset)}
        alt={`artist.name`}
      />
      <TextContainer>
        <h1>{artist.name} </h1>
        <p>Born: {artist.dob}</p>
        <ActiveBetweenRange
          dates={[artist.yearActiveStart, artist.yearActiveEnd]}
        />
        {/* <p>{artist.bio.children.text ? artist.bio.children.text : ""}</p> */}
        <h2>
          Artwork{data.artworksList.nodes.length > 1 ? "s" : ""} in the
          collection
        </h2>
        <ul>
          {data.artworksList.nodes.map((artwork, i) => {
            return (
              <li>
                <a key={`artwork${i}`} href={`/art/${artwork.slug.current}`}>
                  {artwork.title}
                </a>
              </li>
            );
          })}
        </ul>
      </TextContainer>
    </ArtistLayout>
  );
}

export const query = graphql`
  query ($slug: String!) {
    artistData: sanityArtist(slug: { current: { eq: $slug } }) {
      id
      dob
      image {
        asset {
          gatsbyImageData(placeholder: DOMINANT_COLOR, height: 300)
        }
      }
      name
      slug {
        current
      }
      yearActiveEnd
      yearActiveStart
      bio {
        children {
          text
        }
      }
    }
    artworksList: allSanityArt(
      filter: { artist: { slug: { current: { eq: $slug } } } }
    ) {
      nodes {
        title
        slug {
          current
        }
      }
    }
  }
`;
